<template>
    <div class="error-wrapper">
        <img src="/assets/remittance-assets/img/transcrypt-logo-colour.png"/>
        <h3 class="m-2">
            Oops ! This page cannot be found.
        </h3>
        <h6>
            If you have any questions, please contact support at <a
                href="mailto:support@transcryptglobal.com?subject=Page%20Not%20Found">support@transcryptglobal.com</a>
        </h6>
    </div>

</template>

<script>
    export default {
        name: 'ErrorPage',
        data() {
            return {}
        },
        methods: {

        }
    }

</script>
<style scoped>
.error-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0 auto;
    flex-direction: column;

}

    img {
        max-width: 150px;
        margin-bottom:20px
    }

    h6{
        font-weight:700;
    }
</style>